<template>
  <div class="position-relative h-100">
    <!-- Content -->
    <b-container v-if="game_state != 'flow'" class="pb-xl-0 h-100 d-flex flex-column">
      <!-- Start video -->
      <div class="h-100 d-flex align-items-center flex-column justify-content-center" v-if="localVideoSrcObject === null && game_state === ''">
<!--      <div class="h-100 d-flex align-items-center flex-column justify-content-center" v-if="false">-->
        <p class="h1 font-weight-normal text-center">
          Start video
        </p>

        <button class="mb-3 start-video-btn" ref="start_video_btn" @click="start_stream">
          <svg class="start-video-button-icon" style="margin-bottom: 6px;" width="50" height="56" viewBox="0 0 40 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M31.794 13.9006C33.061 12.6453 35.1411 12.6329 36.1867 14.0746C37.8424 16.3574 38.9954 18.9742 39.554 21.7552C40.3248 25.5921 39.9297 29.5693 38.4186 33.1838C36.9074 36.7983 34.3482 39.8878 31.0645 42.0616C27.7808 44.2353 23.92 45.3957 19.9706 45.396C16.0211 45.3963 12.1602 44.2365 8.8761 42.0632C5.59205 39.89 3.03233 36.8009 1.52064 33.1866C0.00895804 29.5724 -0.386802 25.5952 0.38341 21.7582C0.94165 18.9771 2.09422 16.3602 3.74957 14.077C4.79491 12.6352 6.87502 12.6473 8.14225 13.9024C9.40949 15.1575 9.3679 17.1798 8.4395 18.6981C7.63082 20.0206 7.05586 21.4793 6.74825 23.0118C6.22833 25.6019 6.49548 28.2865 7.51591 30.7263C8.53633 33.166 10.2642 35.2512 12.481 36.7182C14.6978 38.1852 17.304 38.9681 19.9701 38.9679C22.6361 38.9677 25.2421 38.1844 27.4587 36.717C29.6753 35.2497 31.4029 33.1642 32.4229 30.7244C33.443 28.2845 33.7097 25.5998 33.1894 23.0097C32.8815 21.4773 32.3064 20.0188 31.4975 18.6964C30.5688 17.1782 30.5269 15.1559 31.794 13.9006Z" 
                  fill="#0E0E3D"/>
            <path d="M19.9692 22.9512V3" stroke="#0E0E3D" stroke-width="5.6" stroke-linecap="round"/>
          </svg>
        </button>

        <p class="text-white text-center mb-0">
          Hi {{USER_ME.nick_name}}, <br> to start the game please turn on the camera ;)
        </p>
      </div>
      <!-- Start video END -->

      <!-- Choose bet -->
      <div class="flex-grow-1 d-flex flex-column align-items-center justify-content-center" v-else-if="game_state === 'choose-bet'">
        <p class="bet-title mb-0" style="margin-bottom: 90px;">
          Choose bet
        </p>

        <b-row class="w-100 mb-4">
          <b-col class="d-flex align-items-center" cols="12">
            <div class="w-100 d-flex flex-column align-items-center">
              <div class="d-flex align-items-center bet-emoji-container">
                <img src="@/assets/images/emoji-1.png" class="bet-emoji" width="244" v-show="choosed_bet <= 10" alt="">

                <img src="@/assets/images/emoji-2.png" class="bet-emoji" width="244" v-show="choosed_bet <= 100 && choosed_bet > 10" alt="">

                <img src="@/assets/images/emoji-3.png" class="bet-emoji" width="244" v-show="choosed_bet <= 5000 && choosed_bet > 100" alt="">
              </div>

              <div class="d-flex align-items-center bet-text-container">
                <p v-html="choosed_bet" class="font-weight-normal bet-text" :style="{opacity: choosed_bet === 5 ? 1 : 0}" ref="bet" :class=" choosed_bet <= 10 ? 'display-4' :
                                                       choosed_bet <= 20 ? 'display-3' :
                                                       choosed_bet <= 500 ? 'display-2' :
                                                       choosed_bet <= 5000 ? 'display-1' : 'h1' " />
              </div>
            </div>
          </b-col>

          <b-col class="mx-auto" cols="12" lg="8">
            <input type="range" class="w-100 bet-range" v-model="selectedBet" :min="0" :max="bets.length - 1" step="1">

            <div class="d-flex justify-content-between">
              <a :class="{'text-white' : choosed_bet === 5}" class="link-gray-200 link" @click="selectedBet = 0" style="width: 20px"> 5 </a>

              <a :class="{'text-white' : choosed_bet === 10}" class="link-gray-200 link" @click="selectedBet = 1" style="width: 20px"> 10 </a>

              <a :class="{'text-white' : choosed_bet === 20}" class="link-gray-200 link" @click="selectedBet = 2" style="width: 20px"> 20 </a>

              <a :class="{'text-white' : choosed_bet === 100}" class="link-gray-200 link" @click="selectedBet = 3" style="width: 20px"> 100 </a>

              <a :class="{'text-white' : choosed_bet === 500}" class="link-gray-200 link" @click="selectedBet = 4" style="width: 20px"> 500 </a>

              <a :class="{'text-white' : choosed_bet === 5000}" class="link-gray-200 link" @click="selectedBet = 5" style="width: 20px"> 5000 </a>
            </div>
          </b-col>
        </b-row>

        <b-btn class="button-gradient-primary scale-drawdown-animation px-5 mb-3" pill @click="start_game">
          <span class="h4">
            PLAY
          </span>
        </b-btn>

        <b-btn variant="link" @click="stopVideo">
          Cancel
        </b-btn>
      </div>
      <!-- Choose bet END -->

      <!-- Game actions -->
      <div v-if="game_state !== 'choose-bet' && game_state !== ''" class="h-100 d-flex flex-column align-items-center justify-content-center">
        <div class="px-3 w-100">
          <!-- Loading state -->
          <div v-if="game_state === 'loading'" class="game-action-wait-bet d-flex align-items-center justify-content-center flex-column">
            <div class="players-in-waiting-wrapper mb-3 text-white">
              Waiting for players
            </div>

            <b-col cols="12" lg="4" class="d-flex justify-content-between mb-3" style="gap: 16px">
              <div class="pool-player-wait-card animate__animated animate__infinite" :class="{'active': players_in_pool > 0, 'animate__pulse' : players_in_pool <= 0}">
                <div class="d-flex flex-column align-items-center" v-if="players_in_pool > 0">
                  <svg  width="34" height="44" viewBox="0 0 34 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17 0C11.0885 0 6.2963 4.78416 6.2963 10.6857C6.2963 16.5873 11.0885 21.3714 17 21.3714C22.9115 21.3714 27.7037 16.5873 27.7037 10.6857C27.7037 4.78416 22.9115 0 17 0Z" fill="#55E19E"/>
                  <path d="M23.3166 26.1254C19.132 25.4587 14.868 25.4587 10.6834 26.1254L10.2348 26.1969C4.33858 27.1363 0 32.2136 0 38.1745C0 41.3918 2.61256 44 5.83532 44H28.1647C31.3874 44 34 41.3918 34 38.1745C34 32.2136 29.6614 27.1363 23.7652 26.1969L23.3166 26.1254Z" fill="#55E19E"/>
                </svg>

                  <p class="mb-0 h6 mt-2 text-success" v-if="pool_players[0]">
                    {{ pool_players[0].nick_name.length > 15 ? pool_players[0].nick_name.slice(0, 15) + '...' : pool_players[0].nick_name }}
                  </p>
                </div>
                
                <svg v-else width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.69728 18.5232L6.13122 17.9522L3.69728 18.5232ZM3.69728 10.7923L6.13122 11.3633L3.69728 10.7923ZM25.6182 10.7923L28.0521 10.2214L25.6182 10.7923ZM25.6182 18.5232L28.0521 19.0941L25.6182 18.5232ZM18.5232 25.6182L17.9522 23.1843L17.9522 23.1843L18.5232 25.6182ZM10.7923 25.6182L10.2214 28.0521L10.7923 25.6182ZM10.7923 3.69728L10.2214 1.26334L10.7923 3.69728ZM18.5232 3.69728L19.0941 1.26334L19.0941 1.26334L18.5232 3.69728ZM26.9822 30.5178C27.9585 31.4941 29.5415 31.4941 30.5178 30.5178C31.4941 29.5415 31.4941 27.9585 30.5178 26.9822L26.9822 30.5178ZM6.13122 17.9522C5.62293 15.7853 5.62293 13.5302 6.13122 11.3633L1.26335 10.2214C0.578885 13.1394 0.578885 16.1761 1.26335 19.0941L6.13122 17.9522ZM23.1843 11.3633C23.6926 13.5302 23.6926 15.7853 23.1843 17.9522L28.0521 19.0941C28.7366 16.1761 28.7366 13.1394 28.0521 10.2214L23.1843 11.3633ZM17.9522 23.1843C15.7853 23.6926 13.5302 23.6926 11.3633 23.1843L10.2214 28.0521C13.1394 28.7366 16.1761 28.7366 19.0941 28.0521L17.9522 23.1843ZM11.3633 6.13122C13.5302 5.62293 15.7853 5.62293 17.9522 6.13122L19.0941 1.26334C16.1761 0.578885 13.1394 0.578886 10.2214 1.26334L11.3633 6.13122ZM11.3633 23.1843C8.7672 22.5753 6.74017 20.5483 6.13122 17.9522L1.26335 19.0941C2.30597 23.5389 5.77656 27.0095 10.2214 28.0521L11.3633 23.1843ZM19.0941 28.0521C23.5389 27.0095 27.0095 23.5389 28.0521 19.0941L23.1843 17.9522C22.5753 20.5483 20.5483 22.5753 17.9522 23.1843L19.0941 28.0521ZM17.9522 6.13122C20.5483 6.74017 22.5753 8.7672 23.1843 11.3633L28.0521 10.2214C27.0095 5.77655 23.5389 2.30597 19.0941 1.26334L17.9522 6.13122ZM10.2214 1.26334C5.77655 2.30597 2.30597 5.77655 1.26335 10.2214L6.13122 11.3633C6.74017 8.7672 8.7672 6.74017 11.3633 6.13122L10.2214 1.26334ZM21.7903 25.3259L26.9822 30.5178L30.5178 26.9822L25.3259 21.7903L21.7903 25.3259Z" fill="#B3B3C2"/>
                </svg>
              </div>

              <div class="pool-player-wait-card animate__animated animate__infinite" :class="{'active': players_in_pool > 1, 'animate__pulse' : players_in_pool <= 1}">

              <div class="d-flex flex-column align-items-center" v-if="players_in_pool > 1">
                <svg  width="34" height="44" viewBox="0 0 34 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17 0C11.0885 0 6.2963 4.78416 6.2963 10.6857C6.2963 16.5873 11.0885 21.3714 17 21.3714C22.9115 21.3714 27.7037 16.5873 27.7037 10.6857C27.7037 4.78416 22.9115 0 17 0Z" fill="#55E19E"/>
                  <path d="M23.3166 26.1254C19.132 25.4587 14.868 25.4587 10.6834 26.1254L10.2348 26.1969C4.33858 27.1363 0 32.2136 0 38.1745C0 41.3918 2.61256 44 5.83532 44H28.1647C31.3874 44 34 41.3918 34 38.1745C34 32.2136 29.6614 27.1363 23.7652 26.1969L23.3166 26.1254Z" fill="#55E19E"/>
                </svg>

                <p class="mb-0 h6 mt-2 text-success" v-if="pool_players[1]">
                  {{ pool_players[1].nick_name.length > 15 ? pool_players[1].nick_name.slice(0, 15) + '...' : pool_players[1].nick_name }}
                </p>
              </div>

                <svg v-else width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.69728 18.5232L6.13122 17.9522L3.69728 18.5232ZM3.69728 10.7923L6.13122 11.3633L3.69728 10.7923ZM25.6182 10.7923L28.0521 10.2214L25.6182 10.7923ZM25.6182 18.5232L28.0521 19.0941L25.6182 18.5232ZM18.5232 25.6182L17.9522 23.1843L17.9522 23.1843L18.5232 25.6182ZM10.7923 25.6182L10.2214 28.0521L10.7923 25.6182ZM10.7923 3.69728L10.2214 1.26334L10.7923 3.69728ZM18.5232 3.69728L19.0941 1.26334L19.0941 1.26334L18.5232 3.69728ZM26.9822 30.5178C27.9585 31.4941 29.5415 31.4941 30.5178 30.5178C31.4941 29.5415 31.4941 27.9585 30.5178 26.9822L26.9822 30.5178ZM6.13122 17.9522C5.62293 15.7853 5.62293 13.5302 6.13122 11.3633L1.26335 10.2214C0.578885 13.1394 0.578885 16.1761 1.26335 19.0941L6.13122 17.9522ZM23.1843 11.3633C23.6926 13.5302 23.6926 15.7853 23.1843 17.9522L28.0521 19.0941C28.7366 16.1761 28.7366 13.1394 28.0521 10.2214L23.1843 11.3633ZM17.9522 23.1843C15.7853 23.6926 13.5302 23.6926 11.3633 23.1843L10.2214 28.0521C13.1394 28.7366 16.1761 28.7366 19.0941 28.0521L17.9522 23.1843ZM11.3633 6.13122C13.5302 5.62293 15.7853 5.62293 17.9522 6.13122L19.0941 1.26334C16.1761 0.578885 13.1394 0.578886 10.2214 1.26334L11.3633 6.13122ZM11.3633 23.1843C8.7672 22.5753 6.74017 20.5483 6.13122 17.9522L1.26335 19.0941C2.30597 23.5389 5.77656 27.0095 10.2214 28.0521L11.3633 23.1843ZM19.0941 28.0521C23.5389 27.0095 27.0095 23.5389 28.0521 19.0941L23.1843 17.9522C22.5753 20.5483 20.5483 22.5753 17.9522 23.1843L19.0941 28.0521ZM17.9522 6.13122C20.5483 6.74017 22.5753 8.7672 23.1843 11.3633L28.0521 10.2214C27.0095 5.77655 23.5389 2.30597 19.0941 1.26334L17.9522 6.13122ZM10.2214 1.26334C5.77655 2.30597 2.30597 5.77655 1.26335 10.2214L6.13122 11.3633C6.74017 8.7672 8.7672 6.74017 11.3633 6.13122L10.2214 1.26334ZM21.7903 25.3259L26.9822 30.5178L30.5178 26.9822L25.3259 21.7903L21.7903 25.3259Z" fill="#B3B3C2"/>
                </svg>
              </div>

              <div class="pool-player-wait-card animate__animated animate__infinite animation_delay" :class="{'active': players_in_pool > 2, 'animate__pulse' : players_in_pool <= 2}">
                <div class="d-flex flex-column align-items-center" v-if="players_in_pool > 2">
                <svg  width="34" height="44" viewBox="0 0 34 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17 0C11.0885 0 6.2963 4.78416 6.2963 10.6857C6.2963 16.5873 11.0885 21.3714 17 21.3714C22.9115 21.3714 27.7037 16.5873 27.7037 10.6857C27.7037 4.78416 22.9115 0 17 0Z" fill="#55E19E"/>
                  <path d="M23.3166 26.1254C19.132 25.4587 14.868 25.4587 10.6834 26.1254L10.2348 26.1969C4.33858 27.1363 0 32.2136 0 38.1745C0 41.3918 2.61256 44 5.83532 44H28.1647C31.3874 44 34 41.3918 34 38.1745C34 32.2136 29.6614 27.1363 23.7652 26.1969L23.3166 26.1254Z" fill="#55E19E"/>
                </svg>

                <p class="mb-0 h6 mt-2 text-success" v-if="pool_players[2]">
                  {{ pool_players[2].nick_name.length > 15 ? pool_players[2].nick_name.slice(0, 15) + '...' : pool_players[2].nick_name }}
                </p>
              </div>

                <svg v-else width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.69728 18.5232L6.13122 17.9522L3.69728 18.5232ZM3.69728 10.7923L6.13122 11.3633L3.69728 10.7923ZM25.6182 10.7923L28.0521 10.2214L25.6182 10.7923ZM25.6182 18.5232L28.0521 19.0941L25.6182 18.5232ZM18.5232 25.6182L17.9522 23.1843L17.9522 23.1843L18.5232 25.6182ZM10.7923 25.6182L10.2214 28.0521L10.7923 25.6182ZM10.7923 3.69728L10.2214 1.26334L10.7923 3.69728ZM18.5232 3.69728L19.0941 1.26334L19.0941 1.26334L18.5232 3.69728ZM26.9822 30.5178C27.9585 31.4941 29.5415 31.4941 30.5178 30.5178C31.4941 29.5415 31.4941 27.9585 30.5178 26.9822L26.9822 30.5178ZM6.13122 17.9522C5.62293 15.7853 5.62293 13.5302 6.13122 11.3633L1.26335 10.2214C0.578885 13.1394 0.578885 16.1761 1.26335 19.0941L6.13122 17.9522ZM23.1843 11.3633C23.6926 13.5302 23.6926 15.7853 23.1843 17.9522L28.0521 19.0941C28.7366 16.1761 28.7366 13.1394 28.0521 10.2214L23.1843 11.3633ZM17.9522 23.1843C15.7853 23.6926 13.5302 23.6926 11.3633 23.1843L10.2214 28.0521C13.1394 28.7366 16.1761 28.7366 19.0941 28.0521L17.9522 23.1843ZM11.3633 6.13122C13.5302 5.62293 15.7853 5.62293 17.9522 6.13122L19.0941 1.26334C16.1761 0.578885 13.1394 0.578886 10.2214 1.26334L11.3633 6.13122ZM11.3633 23.1843C8.7672 22.5753 6.74017 20.5483 6.13122 17.9522L1.26335 19.0941C2.30597 23.5389 5.77656 27.0095 10.2214 28.0521L11.3633 23.1843ZM19.0941 28.0521C23.5389 27.0095 27.0095 23.5389 28.0521 19.0941L23.1843 17.9522C22.5753 20.5483 20.5483 22.5753 17.9522 23.1843L19.0941 28.0521ZM17.9522 6.13122C20.5483 6.74017 22.5753 8.7672 23.1843 11.3633L28.0521 10.2214C27.0095 5.77655 23.5389 2.30597 19.0941 1.26334L17.9522 6.13122ZM10.2214 1.26334C5.77655 2.30597 2.30597 5.77655 1.26335 10.2214L6.13122 11.3633C6.74017 8.7672 8.7672 6.74017 11.3633 6.13122L10.2214 1.26334ZM21.7903 25.3259L26.9822 30.5178L30.5178 26.9822L25.3259 21.7903L21.7903 25.3259Z" fill="#B3B3C2"/>
                </svg>
              </div>
            </b-col>

            <div class="d-flex align-items-center flex-column">
              <b-btn @click="close_game" variant="link">Stop</b-btn>
            </div>
          </div>
          <!-- Loading state END -->

          <!-- Waiting state -->
          <div v-if="game_state === 'waiting'" class="game-action-wait-bet d-flex align-items-center justify-content-center flex-column">
            <img src="@/assets/coinflip_animation.gif" width="100" class="mb-4" alt="">

            <b-col class="d-flex flex-column" cols="12" lg="9">
              <p class="text-white text-center" v-html="game_state_message" />

              <p class="tip active mb-0" ref="tip" v-html="tip" />
            </b-col>
          </div>
          <!-- Waiting state END -->

          <!-- Choose exile state -->
          <div v-if="game_state === 'choose-exile'" class="game-action-wait-bet h-100">
            <div class="d-flex justify-content-between mb-3">
              <h3 class="font-weight-normal text-center text-white">Choose whose bet to split</h3>

              <div v-if="exile_timer !== null" class="game-timer-cotainer">
                <span class="game-action-timer">
                    <img style="z-index: 20" class="h-100" src="@/assets/icons/sand_clock.svg" alt="">
                    <span class="game-timer-sand" style="width: 30px; z-index: 10;" :style="{'height': exile_timer / time_for_exile * 100 + '%'}" ></span>
                </span>
              </div>
            </div>

            <b-row class="d-flex justify-content-center mb-4 w-100 mx-0">
              <b-col v-for="(player, index) in exile_players" :key="player.nick_name + index" cols="6" lg="4">
                <div class="exile-player-card scale-drawdown-animation" :class="{'selected': player.selected}" @click="select_pool_player(index)">
                  <img class="exile-player-avatar mb-2" :src="player.avatar_url" alt="">
                  <p class="mb-0 text-white">{{player.nick_name}}</p>
                </div>
              </b-col>
            </b-row>

            <div v-if="selected_player !== 'all' && selected_player !== 'none'" class="d-flex justify-content-center">
              <p class="text-white mb-0" v-html="`You voted against ${exile_players.find(player => player.selected).nick_name}`" />
            </div>
            <div v-else-if="selected_player === 'all'" class="d-flex justify-content-center">
              <p class="text-white mb-0" v-html="`You voted against ${exile_players.map(player => player.nick_name).join(' and ')}`"></p>
            </div>

            <div class="text-center mt-3">
              <b-btn class="font-weight-normal scale-drawdown-animation" :class="{'border border-primary text-primary' : selected_player === 'all'}" style="font-size: 24px" @click="vote_against_all">
                Choose all
              </b-btn>
            </div>
          </div>
          <!-- Choose exile state END -->

          <!-- Result state -->
          <div v-if="game_state === 'result'" class="game-action-wait-bet d-flex align-items-center flex-column position-relative">
            <div class="mb-3 d-flex justify-content-between">
              <div class="text-center" v-if="my_result.state === 'won'">
                <p class="text-success h3">You won!!</p>
                <p class="h4">+{{my_result.value}} <img class="mr-1" width="24" :src="require('@/assets/token-icon.svg')" alt=""></p>
              </div>

              <div class="text-center" v-if="my_result.state === 'lost'">
                <p class="text-danger h3">You lost</p>
                <p class="h4">{{my_result.value}} <img class="mr-1" width="24" :src="require('@/assets/token-icon.svg')" alt=""></p>
              </div>

              <div class="text-center" v-if="my_result.state === 'draw'">
                <p class="h3">Draw</p>
              </div>
            </div>

            <b-col cols="12" md="8" lg="6">
              <div class="d-flex justify-content-between">
                <p class="text-white text-center">Decisions</p>

                <b-btn class="close-game-action-window-button ml-auto" @click="close_game()">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 18L6 6" stroke="#C3C1C8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M18 6L6 18" stroke="#C3C1C8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </b-btn>
              </div>

              <b-list-group flush>
                <b-list-group-item v-for="(item, index) in game_results.decisions" class="bg-transparent p-0 mb-3" :key="item.nick_name + index">
                  <p class="text-white mb-1">Player: {{item.nick_name}}</p>
                  <p class="text-white mb-1">Against: {{item.against}}</p>
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </div>
          <!-- Result state END -->
        </div>
      </div>
      <!-- Game actions -->

    </b-container>
    <!-- Content END -->

    <!--  Flow state  -->
    <div v-show="game_state === 'flow'" class="game-flow-container" :class="{'top-0': game_state === 'flow'}">
      <Flicking v-if="mobile" class="h-100" ref="slides">
        <div class="slide-panel-chat">
          <div class="chat d-flex flex-column bg-dark">
            <div class="rounded-top d-flex bg-gray-600 px-4 py-3 align-items-center">
              <img width="50" height="50" class="rounded-circle bg-dark" :src="companion.avatar_url" alt="">
              <p class="mb-0 ml-3 text-white">{{companion.nick_name}}</p>

              <b-btn @click="close_chat" variant="link" class="ml-auto link-gray-400 h-100 p-3">
                <b-icon icon="camera-video" />
              </b-btn>
            </div>

            <ul class="d-flex flex-grow-1 flex-column overflow-scroll-y list-unstyled p-3" ref="message_box">
              <li v-for="(message, index) in messages" :key="message.value + index" class="message" :class="{'my_message': message.my_mes}">
                {{message.value}}
              </li>
            </ul>

            <div class="d-flex  align-items-center p-3">
              <input type="text" class="messenger-input flex-grow-1" placeholder="Write message" v-model="message" @keyup.enter="sent_message()">
              <b-btn class="button-gradient-primary rounded-circle p-2 ml-2 scale-drawdown-animation send-message-button" @click="sent_message()">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.60051 12.9852C9.20999 13.3758 9.20999 14.0089 9.60051 14.3995C9.99103 14.79 10.6242 14.79 11.0147 14.3995L9.60051 12.9852ZM15.8609 9.5533C16.2514 9.16277 16.2514 8.52961 15.8609 8.13908C15.4704 7.74856 14.8372 7.74856 14.4467 8.13908L15.8609 9.5533ZM11.0147 14.3995L15.8609 9.5533L14.4467 8.13908L9.60051 12.9852L11.0147 14.3995Z" fill="white"/>
                  <path d="M13.7302 20.7675L12.8357 21.2148L13.7302 20.7675ZM15.5804 20.6144L14.6246 20.3203L15.5804 20.6144ZM9.93283 13.6201L9.48561 14.5145L9.93283 13.6201ZM10.38 14.0673L11.2745 13.6201L10.38 14.0673ZM3.38572 8.41978L3.67981 9.37556L3.38572 8.41978ZM3.2326 10.27L2.78538 11.1644H2.78538L3.2326 10.27ZM19.1948 3.55543L19.4889 4.51121L19.1948 3.55543ZM20.4447 4.8053L21.4005 5.09938L20.4447 4.8053ZM18.9008 2.59965L3.09164 7.464L3.67981 9.37556L19.4889 4.51121L18.9008 2.59965ZM2.78538 11.1644L9.48561 14.5145L10.38 12.7257L3.67981 9.37555L2.78538 11.1644ZM9.48561 14.5145L12.8357 21.2148L14.6246 20.3203L11.2745 13.6201L9.48561 14.5145ZM16.5361 20.9085L21.4005 5.09938L19.4889 4.51121L14.6246 20.3203L16.5361 20.9085ZM12.8357 21.2148C13.6426 22.8286 16.0055 22.633 16.5361 20.9085L14.6246 20.3203L12.8357 21.2148ZM9.48561 14.5145V14.5145L11.2745 13.6201C11.0809 13.233 10.7671 12.9192 10.38 12.7257L9.48561 14.5145ZM3.09164 7.464C1.3671 7.99462 1.17155 10.3575 2.78538 11.1644L3.67981 9.37555L3.67981 9.37556L3.09164 7.464ZM19.4889 4.51121L19.4889 4.51121L21.4005 5.09938C21.8727 3.56483 20.4353 2.12748 18.9008 2.59965L19.4889 4.51121Z" fill="white"/>
                </svg>
              </b-btn>
              <!--              <b-btn class="button-gradient-primary rounded-circle p-2 ml-2 scale-drawdown-animation" @click="sendWebRTCMessage()">-->
              <!--                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
              <!--                  <path d="M9.60051 12.9852C9.20999 13.3758 9.20999 14.0089 9.60051 14.3995C9.99103 14.79 10.6242 14.79 11.0147 14.3995L9.60051 12.9852ZM15.8609 9.5533C16.2514 9.16277 16.2514 8.52961 15.8609 8.13908C15.4704 7.74856 14.8372 7.74856 14.4467 8.13908L15.8609 9.5533ZM11.0147 14.3995L15.8609 9.5533L14.4467 8.13908L9.60051 12.9852L11.0147 14.3995Z" fill="white"/>-->
              <!--                  <path d="M13.7302 20.7675L12.8357 21.2148L13.7302 20.7675ZM15.5804 20.6144L14.6246 20.3203L15.5804 20.6144ZM9.93283 13.6201L9.48561 14.5145L9.93283 13.6201ZM10.38 14.0673L11.2745 13.6201L10.38 14.0673ZM3.38572 8.41978L3.67981 9.37556L3.38572 8.41978ZM3.2326 10.27L2.78538 11.1644H2.78538L3.2326 10.27ZM19.1948 3.55543L19.4889 4.51121L19.1948 3.55543ZM20.4447 4.8053L21.4005 5.09938L20.4447 4.8053ZM18.9008 2.59965L3.09164 7.464L3.67981 9.37556L19.4889 4.51121L18.9008 2.59965ZM2.78538 11.1644L9.48561 14.5145L10.38 12.7257L3.67981 9.37555L2.78538 11.1644ZM9.48561 14.5145L12.8357 21.2148L14.6246 20.3203L11.2745 13.6201L9.48561 14.5145ZM16.5361 20.9085L21.4005 5.09938L19.4889 4.51121L14.6246 20.3203L16.5361 20.9085ZM12.8357 21.2148C13.6426 22.8286 16.0055 22.633 16.5361 20.9085L14.6246 20.3203L12.8357 21.2148ZM9.48561 14.5145V14.5145L11.2745 13.6201C11.0809 13.233 10.7671 12.9192 10.38 12.7257L9.48561 14.5145ZM3.09164 7.464C1.3671 7.99462 1.17155 10.3575 2.78538 11.1644L3.67981 9.37555L3.67981 9.37556L3.09164 7.464ZM19.4889 4.51121L19.4889 4.51121L21.4005 5.09938C21.8727 3.56483 20.4353 2.12748 18.9008 2.59965L19.4889 4.51121Z" fill="white"/>-->
              <!--                </svg>-->
              <!--              </b-btn>-->
            </div>
          </div>
        </div>

        <div class="slide-panel-video">
          <div ref="companion_cam" class="companion_cam rounded overflow-hidden position-relative">
            <div style="z-index: 1000" v-if="!call_success" class="position-absolute top-0 w-100 h-100 bg-dark d-flex align-items-center justify-content-center">
              <div class="text-center">
                <b-spinner variant="primary" type="grow" />
                <p>Trying to connect</p>

                <p v-if="connect_unavailable">
                  The game has started but the video is not available, use the chat
                </p>
              </div>
            </div>

            <video class="w-100 h-100" autoplay playsinline webkit-playsinline ref="remoteVideo" />

            <div style="z-index: 1100" class="top-0 d-flex align-items-center w-100 p-4">
              <div class="d-flex align-items-center">
                <img width="50" height="50" class="rounded-circle bg-dark" :src="companion.avatar_url" alt="" style="top: 24px; left: 32px">
                <p class="ml-3 mb-0">{{companion.nick_name}}</p>
              </div>

              <div style="z-index: 1100;" v-if="timer !== null" class="game-timer-cotainer ml-auto">
                <span class="game-action-timer">
                    <img style="z-index: 20" class="h-100" src="@/assets/icons/sand_clock.svg" alt="">
                    <span class="game-timer-sand" style="width: 30px; z-index: 10;" :style="{'height': timer / time_for_disscusing * 100 + '%'}" ></span>
                </span>
              </div>
            </div>

            <div style="z-index: 1100" class="chat-cam-button-wrap">
              <div class="position-relative w-100 h-100">
                  <span v-if="new_messages_count > 0" class="new-messages-indicator">
                    {{new_messages_count}}
                  </span>

                <button @click="open_chat" class="chat-cam-button button-gradient-primary">
                  <b-icon icon="chat" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Flicking>

      <b-row class="px-lg-4 px-0 pt-4 h-100" v-else>
        <!-- Media block -->
        <b-col class="d-flex flex-column position-relative" cols="12" lg="8">
          <div ref="companion_cam" class="companion_cam rounded overflow-hidden position-relative mb-3">
            <div style="z-index: 1000" v-if="!call_success" class="position-absolute top-0 w-100 h-100 bg-dark d-flex align-items-center justify-content-center">
              <div class="text-center">
                <b-spinner variant="primary" type="grow" />
                <p>Trying to connect</p>

                <p v-if="!connect_unavailable">
                  The game has started but the video is not available, use the chat
                </p>
              </div>
            </div>

            <video class="w-100 h-100" autoplay playsinline webkit-playsinline ref="remoteVideo" />

            <div style="z-index: 1100" class="position-absolute top-0 d-flex align-items-center w-100 p-4">
              <div class="d-flex align-items-center">
                <img width="50" height="50" class="rounded-circle bg-dark" :src="companion.avatar_url" alt="" style="top: 24px; left: 32px">
                <p class="ml-3 mb-0">{{companion.nick_name}}</p>
              </div>

              <div style="z-index: 1100;" v-if="timer !== null" class="game-timer-cotainer ml-auto">
                <span class="game-action-timer">
                    <img style="z-index: 20" class="h-100" src="@/assets/icons/sand_clock.svg" alt="">
                    <span class="game-timer-sand" style="width: 30px; z-index: 10;" :style="{'height': timer / time_for_disscusing * 100 + '%'}" ></span>
                </span>
              </div>
            </div>

            <div style="z-index: 1100" class="chat-cam-button-wrap">
              <div class="position-relative w-100 h-100">
                  <span v-if="new_messages_count > 0" class="new-messages-indicator">
                    {{new_messages_count}}
                  </span>

                <button @click="open_chat" class="chat-cam-button button-gradient-primary">
                  <b-icon icon="chat" />
                </button>
              </div>
            </div>
          </div>
          <!--            <b-btn class="ml-auto" @click="toggleVideo" variant="primary">-->
          <!--              <b-icon icon="camera-video" class="text-white" />-->
          <!--            </b-btn>-->
        </b-col>
        <!-- Media block END -->

        <!-- Chat block -->
        <b-col class="h-100">
          <div class="chat d-flex flex-column bg-dark">
            <div class="rounded-top d-flex bg-gray-600 px-4 py-3 align-items-center">
              <img width="50" height="50" class="rounded-circle bg-dark" :src="companion.avatar_url" alt="">
              <p class="mb-0 ml-3 text-white">{{companion.nick_name}}</p>
            </div>

            <ul class="d-flex flex-grow-1 flex-column overflow-scroll-y list-unstyled p-3" ref="message_box">
              <li v-for="(message, index) in messages" :key="message.value + index" class="message" :class="{'my_message': message.my_mes}">
                {{message.value}}
              </li>
            </ul>

            <div class="d-flex  align-items-center p-3">
              <input type="text" class="messenger-input flex-grow-1" placeholder="Write message" v-model="message" @keyup.enter="sent_message()">
              <b-btn class="button-gradient-primary rounded-circle p-2 ml-2 scale-drawdown-animation send-message-button" @click="sent_message()">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.60051 12.9852C9.20999 13.3758 9.20999 14.0089 9.60051 14.3995C9.99103 14.79 10.6242 14.79 11.0147 14.3995L9.60051 12.9852ZM15.8609 9.5533C16.2514 9.16277 16.2514 8.52961 15.8609 8.13908C15.4704 7.74856 14.8372 7.74856 14.4467 8.13908L15.8609 9.5533ZM11.0147 14.3995L15.8609 9.5533L14.4467 8.13908L9.60051 12.9852L11.0147 14.3995Z" fill="white"/>
                  <path d="M13.7302 20.7675L12.8357 21.2148L13.7302 20.7675ZM15.5804 20.6144L14.6246 20.3203L15.5804 20.6144ZM9.93283 13.6201L9.48561 14.5145L9.93283 13.6201ZM10.38 14.0673L11.2745 13.6201L10.38 14.0673ZM3.38572 8.41978L3.67981 9.37556L3.38572 8.41978ZM3.2326 10.27L2.78538 11.1644H2.78538L3.2326 10.27ZM19.1948 3.55543L19.4889 4.51121L19.1948 3.55543ZM20.4447 4.8053L21.4005 5.09938L20.4447 4.8053ZM18.9008 2.59965L3.09164 7.464L3.67981 9.37556L19.4889 4.51121L18.9008 2.59965ZM2.78538 11.1644L9.48561 14.5145L10.38 12.7257L3.67981 9.37555L2.78538 11.1644ZM9.48561 14.5145L12.8357 21.2148L14.6246 20.3203L11.2745 13.6201L9.48561 14.5145ZM16.5361 20.9085L21.4005 5.09938L19.4889 4.51121L14.6246 20.3203L16.5361 20.9085ZM12.8357 21.2148C13.6426 22.8286 16.0055 22.633 16.5361 20.9085L14.6246 20.3203L12.8357 21.2148ZM9.48561 14.5145V14.5145L11.2745 13.6201C11.0809 13.233 10.7671 12.9192 10.38 12.7257L9.48561 14.5145ZM3.09164 7.464C1.3671 7.99462 1.17155 10.3575 2.78538 11.1644L3.67981 9.37555L3.67981 9.37556L3.09164 7.464ZM19.4889 4.51121L19.4889 4.51121L21.4005 5.09938C21.8727 3.56483 20.4353 2.12748 18.9008 2.59965L19.4889 4.51121Z" fill="white"/>
                </svg>
              </b-btn>
<!--              <b-btn class="button-gradient-primary rounded-circle p-2 ml-2 scale-drawdown-animation" @click="sendWebRTCMessage()">-->
<!--                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                  <path d="M9.60051 12.9852C9.20999 13.3758 9.20999 14.0089 9.60051 14.3995C9.99103 14.79 10.6242 14.79 11.0147 14.3995L9.60051 12.9852ZM15.8609 9.5533C16.2514 9.16277 16.2514 8.52961 15.8609 8.13908C15.4704 7.74856 14.8372 7.74856 14.4467 8.13908L15.8609 9.5533ZM11.0147 14.3995L15.8609 9.5533L14.4467 8.13908L9.60051 12.9852L11.0147 14.3995Z" fill="white"/>-->
<!--                  <path d="M13.7302 20.7675L12.8357 21.2148L13.7302 20.7675ZM15.5804 20.6144L14.6246 20.3203L15.5804 20.6144ZM9.93283 13.6201L9.48561 14.5145L9.93283 13.6201ZM10.38 14.0673L11.2745 13.6201L10.38 14.0673ZM3.38572 8.41978L3.67981 9.37556L3.38572 8.41978ZM3.2326 10.27L2.78538 11.1644H2.78538L3.2326 10.27ZM19.1948 3.55543L19.4889 4.51121L19.1948 3.55543ZM20.4447 4.8053L21.4005 5.09938L20.4447 4.8053ZM18.9008 2.59965L3.09164 7.464L3.67981 9.37556L19.4889 4.51121L18.9008 2.59965ZM2.78538 11.1644L9.48561 14.5145L10.38 12.7257L3.67981 9.37555L2.78538 11.1644ZM9.48561 14.5145L12.8357 21.2148L14.6246 20.3203L11.2745 13.6201L9.48561 14.5145ZM16.5361 20.9085L21.4005 5.09938L19.4889 4.51121L14.6246 20.3203L16.5361 20.9085ZM12.8357 21.2148C13.6426 22.8286 16.0055 22.633 16.5361 20.9085L14.6246 20.3203L12.8357 21.2148ZM9.48561 14.5145V14.5145L11.2745 13.6201C11.0809 13.233 10.7671 12.9192 10.38 12.7257L9.48561 14.5145ZM3.09164 7.464C1.3671 7.99462 1.17155 10.3575 2.78538 11.1644L3.67981 9.37555L3.67981 9.37556L3.09164 7.464ZM19.4889 4.51121L19.4889 4.51121L21.4005 5.09938C21.8727 3.56483 20.4353 2.12748 18.9008 2.59965L19.4889 4.51121Z" fill="white"/>-->
<!--                </svg>-->
<!--              </b-btn>-->
            </div>
          </div>
        </b-col>
        <!-- Chat block END -->
      </b-row>
    </div>
    <!--  Flow state END -->

    <!--  locale video -->
    <div ref="localeVideo" v-show="game_state === 'flow'" class="localeVideo rounded overflow-hidden">
      <video class="w-100 h-100 d-none" ref="localVideo" playsinline webkit-playsinline autoplay muted></video>
    </div>
    <!--  locale video END -->
  </div>
</template>

<script>
const stun = require('stun');

const init_data = () => {
  return {
    // Simple vars
    timerActive: false,
    stunListUrl: 'https://raw.githubusercontent.com/pradt2/always-online-stun/master/valid_hosts.txt',
    dataChannel: null,
    call_success: false,
    connect_unavailable: false,
    numServers: 5,
    isLoading: true,
    selectedBet: 0,
    top5: null,
    localVideoSrcObject: null,
    remoteVideoSrcObject: null,
    players_connected: false,
    accept_video: true,
    game_state: '', // "choose-bet", "loading", "waiting", "flow", "choose-exile", "result"
    game_state_message: '',
    game_data: '',
    game_set: false,
    key: 0,
    timer: null,
    selected_player: 'all',
    message: '',
    websocket: '',
    error_message: '',
    players_in_pool: 1,
    my_pool_number: 1,
    choosed_split: '50/50',
    initiator_id: '',
    data_channel: null,
    setTimer: null,
    time_for_disscusing: 120,
    time_for_exile: 20,
    exile_timer: null,
    chat_is_open: false,
    new_messages_count: 0,
    tip: '',

    // Objects
    pc: {},
    localStream: {},
    game_results: {
      action: "game_results",
      decisions: [],
      result: [],
    },
    my_result: {
      player: '',
      nick_name: "",
      state: "",
      value: ''
    },
    companion: {
      avatar_url: "",
      id: "",
      nick_name: "",
      player: null,
      selected: false,
    },

    // Arrays
    bestServers: [],
    tips: [
      "Success is the art of negotiating with fate",
      "The best strategy in Tigpok: play like a pro, but bet like a beginner.",
      "There is always a best strategy in Tigpok - just don't let your opponents know what that strategy is.",
      "In the Tigpok game, as in politics: personal charm is a weapon, and lies are a tool.",
      "It is not surprising that those who tell the truth often win in Tigpok, because the truth is also a weapon.",
      "Tigpok is a theater, and all players are actors.",
      "Psychology is an important tool in recognizing lies, but even more important in recognizing the truth.",
      "The ability to come to an agreement with any person is one of the most important traits in a person’s success; you just need to monetize it.",
      "Trust is not an important strategy in life, and especially not in this game.",
      "Quotes are the haiku of propagandists.",
      "Life is an eternal Tigpok, you always choose who to trust. Why not make money from it?",
      "Tigpok is a mirror in which we see the reflection of our decisions.",
      "At Tigpok we explore the world not only outside, but also inside ourselves.",
      "Tigpok is a path to understanding life, where the rules are created and changed by us.",
      "Tigpok teaches us to make decisions and learn from mistakes, just like in real life.",
      "Through Tigpok we can experience different roles and understand others' experiences, which promotes empathy and understanding.",
      "People trust more those who are successful.",
      "Tigpok confirms the end and justifies the means.",
      "Sometimes a trick is the only way to get what you deserve.",
      "Success is not just an accident.",
      "People love to communicate, deceive each other and make money from communication and lies - all of this is in Tigpok.",
      "Convincing people to trust you and then making money from it is what politics and Tigpok have in common.",
      "The world has many problems, but even more opportunities.",
      "Opportunities always lie with those who seek them.",
      "You don't need great opportunities to get started. Start with what you have and they will come as you move forward.",
      "Knowing yourself helps you change yourself, adaptation is the key to survival in Tigpok.",
      "A bird relies on its wings, a man on strategy.",
      "The best moment to act is now.",
      "The desire for victory without a plan is just a dream.",
      "Luck is a choice, not a result of circumstances.",
      "We learn from mistakes, we grow with experience.",
      "The weak look for excuses, the strong prepare them in advance.",
      "Tired of sitting without money? Get up!",
      "Don't be afraid to fight someone who doesn't know how.",
      "You live as well as you can negotiate."
    ],
    rtcConfig: {
      iceServers: []
    },

    turnServers: [
      {
        urls: "turn:a.relay.metered.ca:80",
        username: "02459d4c696c7280c89ff2cf",
        credential: "puyg3aNV1ubJVSkn",
      },
      {
        urls: "turn:a.relay.metered.ca:80?transport=tcp",
        username: "02459d4c696c7280c89ff2cf",
        credential: "puyg3aNV1ubJVSkn",
      },
      {
        urls: "turn:a.relay.metered.ca:443",
        username: "02459d4c696c7280c89ff2cf",
        credential: "puyg3aNV1ubJVSkn",
      },
      {
        urls: "turn:a.relay.metered.ca:443?transport=tcp",
        username: "02459d4c696c7280c89ff2cf",
        credential: "puyg3aNV1ubJVSkn",
      }
    ],
    stunServers: null,
    iceServers: null,
    splits: [
      {value: '20/80',
        selected: false},
      {value: '40/60',
        selected: false},
      {value: '50/50',
        selected: false},
      {value: '60/40',
        selected: false},
      {value: '80/20',
        selected: false},
    ],
    pool_players: [],
    exile_players: [{nick_name: '', selected: false}, {nick_name: '', selected: false}],
    bets: [5, 10, 20, 100, 500, 5000],
    messages: [{
      value: 'test',
      my_mes: true,
    }, {
      value: 'test',
      my_mes: false,
    }],
    pingTimes: [],
  }
}

import {mapGetters} from 'vuex'

export default {
  name: 'Home_component',

  data() {
    return {
      ...init_data(),
      mobile: false,
    }
  },

  watch: {
    selectedBet() {
      if(this.game_state === 'choose-bet') {
        this.$refs.bet.classList.remove('bet-animation')

        setTimeout(() => {
          this.$refs.bet.classList.add('bet-animation')
        }, 100)
      }
    },

    game_state(val) {
      if(val === "waiting") {
        this.getTip()
      }
    }
  },

  computed: {
    ...mapGetters(['USER_ME', 'show_home']),

    choosed_bet() {
      return this.bets[this.selectedBet];
    },
  },

  methods: {
    getRandom(old_val, max) {
      let new_val = Math.floor(Math.random() * max)
      if(new_val !== old_val) {
        return new_val
      } else {
        this.getRandom(old_val, max)
      }
    },

    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    },

    getTip() {
      let a = []

      let shuffleTips = this.shuffleArray(this.tips)

      let random = Math.floor(Math.random() * shuffleTips.length)

      shuffleTips.splice(random, 0, ...a);

      this.tip = shuffleTips[0]

      let i = 1

      this.showTip(i, shuffleTips)
    },

    showTip(i, tips) {
      setTimeout(() => {
        this.$refs.tip.classList.remove('active')

        setTimeout(() => {
          this.$refs.tip.classList.add('active')
          this.tip = tips[i]
          i++

          this.showTip(i >= tips.length ? 0 : i , tips)
        }, 2000)
      }, 10000)

      if(i <= this.tips.length) {
      }
    },

    //STUN
    getBestStunServers() {
      fetch(this.stunListUrl)
          .then(response => response.text())
          .then(stunList => {
            const stunServers = stunList.split('\n')

            const responseTimes = []

            function measureResponseTime(serverUrl) {
              const startTime = new Date()
              const xhr = new XMLHttpRequest()
              xhr.open('GET', `${serverUrl}`, true)
              xhr.timeout = 1000
              xhr.onload = function() {
                const endTime = new Date()
                const responseTime = endTime - startTime
                responseTimes.push({ server: serverUrl, responseTime })
              }
              xhr.ontimeout = function() {
                const endTime = new Date()
                const responseTime = endTime - startTime
                responseTimes.push({ server: serverUrl, responseTime })
              }
              xhr.send()
            }

            stunServers.forEach(serverUrl => {
              measureResponseTime(serverUrl)
            })

            setTimeout(() => {
              responseTimes.sort((a, b) => a.responseTime - b.responseTime)
              const bestServers = responseTimes.slice(0, this.numServers).map(obj => obj.server)
              this.bestServers = bestServers

              this.isLoading = false
            }, 5000)
          })
          .catch(error => {
            this.isLoading = false
          })
    },

    async getSTUNservers() {
      return new Promise((resolve, reject) => {
      fetch('https://raw.githubusercontent.com/pradt2/always-online-stun/master/valid_hosts.txt')
          .then(response => response.text())
          .then(data => {
            this.rtcConfig.iceServers = data.trim().split('\n').map(function(url) {
              return {
                urls: `stun:${url}`
              }
            });


            resolve(data)
          })
          .catch(error => {
            reject(error)
          });
      })
    },

    async checkStun(servers) {
      return new Promise((resolve, reject) => {
        try {
          servers.forEach((server, index) => {
            const configuration = {'iceServers': [{'urls': 'stun:' + server}]};
            const peerConnection = new RTCPeerConnection(configuration);
            peerConnection.createDataChannel('test');

            peerConnection.createOffer()
                .then(offer => peerConnection.setLocalDescription(offer))

            let startTime;

            peerConnection.onicecandidate = (event) => {
              if (event.candidate) {
                this.pingTimes.push({
                  server: server,
                  time: Date.now() - startTime
                })
                peerConnection.close();
              }
            };

            startTime = Date.now();

            if(index === this.pingTimes.length) {
              resolve()
              peerConnection.close();
            }
          });
        }
        catch(er) {
          reject()
        }
      })
    },

    //BET
    choose_bet(index) {
      this.bets.forEach(element => {
        element.active = false
      });

      this.bets[index].active = true
      this.choosed_bet = this.bets[index].value
    },
    choose_split(index) {
      this.splits.forEach(element => {
        element.selected = false
      });

      this.splits[index].selected = true
      this.choosed_split = this.splits[index].value
    },
    select_pool_player(index){
      this.choosed_split = '50/50'
      if(this.select_pool_player === null) {
        setTimeout(() => this.splits.find(e => e.value === '50/50').selected = true, 250)
      }

      this.exile_players.forEach(x => {
        x.selected = false
      })

      this.splits.forEach(element => {
        element.selected = false
      });

      this.splits.find(x => x.value === '50/50').selected = true
      this.exile_players[index].selected = true
      this.selected_player = this.exile_players[index].player
    },
    vote_against_all() {
      this.exile_players.forEach(x => {
        x.selected = false
      })

      this.splits.forEach(element => {
        element.selected = false
      });

      this.selected_player = 'all'
    },

    //TIMER
    start_timer() {
      if(!this.timerActive) {
        this.timerActive = true
        let seconds = this.time_for_disscusing
        let js_seconds = seconds * 1000

        this.setTimer = setTimeout(()=>{
          this.timerActive = false
          this.call_success = false
          this.connect_unavailable = false
          this.make_move()
        }, js_seconds)

        let timer = setInterval(() => {
          if(seconds >= 0) {
            // this.timer = this.convert_to_time(seconds)
            this.timer = seconds
            seconds--
          } else {
            clearInterval(timer)
          }
        }, 1000)
      }
    },
    convert_to_time(d) {
      d = Number(d);
      var h = Math.floor(d / 3600);
      var m = Math.floor(d % 3600 / 60);
      var s = Math.floor(d % 3600 % 60);

      var hDisplay = h > 0 ? h = (h < 10 ?  '0' + h + ':' : h + ':') : '';
      var mDisplay = m > 0 ? m + ':'  : '';
      // = (m < 10 ?  '0' + m + ':' : m + ':')
      var sDisplay = s > 0 ? s = (s < 10 ?  '0' + s + ':' : s) : '00';
      return hDisplay + mDisplay + sDisplay;
    },
    make_move() {
      if(this.my_pool_number === 1) {
        if(!this.game_set) {
          this.game_set = true
          this.game_state = 'waiting'
          this.$store.dispatch('change_g_game_state', 'waiting')
          this.game_state_message = `Waiting for ${this.exile_players[0]?.nick_name} and ${this.exile_players[1]?.nick_name} to finish their discussion.`
        } else if (this.game_set) {
          this.close_rtc()
          this.game_state = "choose-exile"
          this.$store.dispatch('change_g_game_state', 'choose-exile')
          this.stopVideo()
          this.end_game()
        }

      } else if(this.my_pool_number === 2) {
        let message = JSON.stringify({action: 'call', message: '', destination: this.pool_players.find(x => x.player === 3).id})
        if(!this.game_set) {
          this.close_rtc()

          this.create_peerConnection()
          this.websocket.send(message)
          this.game_set = true
        } else if (this.game_set) {
          this.game_state = "choose-exile"
          this.$store.dispatch('change_g_game_state', "choose-exile")
          this.stopVideo()
          this.end_game()
        }
      }  else if(this.my_pool_number === 3) {
        let message = JSON.stringify({action: 'call', message: '', destination: this.pool_players.find(x => x.player === 1).id})
        if(!this.game_set) {
          this.close_rtc()
          this.create_peerConnection()

          this.websocket.send(message)
          this.game_set = true
        } else if (this.game_set) {
          this.game_state = 'choose-exile'
          this.$store.dispatch('change_g_game_state', "choose-exile")
          this.stopVideo()
          this.end_game()
          let exile_message = JSON.stringify({action: 'exile', message: '', destination: this.pool_players.find(x => x.player === 2).id})
          this.websocket.send(exile_message)
        }
      }
      this.players_connected = false
    },


    //RTC
    create_peerConnection() {
      this.new_messages_count = 0

      this.pc = new RTCPeerConnection({
        iceServers: this.turnServers
      })

      this.pc.onicecandidate = e => {
        let message = {}

        if(e.candidate) {
          message = JSON.stringify({action: 'candidate', candidate: e.candidate, destination: this.initiator_id})
          this.websocket.send(message)
        }
      }

      this.pc.onconnectionstatechange = (event) => {

      };

      this.pc.ontrack = e => {
        this.track = e.streams[0]
      }

      this.localStream.getTracks().forEach(track => this.pc.addTrack(track, this.localStream));

      // const constraints = {
      //   width: { max: 240 },
      //   height: { max: 240 },
      // }
      // this.localStream.getVideoTracks()[0].applyConstraints(constraints)

      this.connectToDatachannel()
    },
    close_rtc() {
      this.$refs.remoteVideo.srcObject = null
      if(this.pc.close) {
        this.pc.close()
      }
      this.pc = {}
    },
    setStream() {
      this.$refs.remoteVideo.srcObject = this.track
    },
    setDataChannel() {
      this.dataChannel = this.pc.createDataChannel('tigpok_chat', {
        reliable: true
      })

      this.dataChannel.onerror = (error) => {
      };

      // this.dataChannel.onmessage = (event) => {
      //   this.$bvToast.toast(event.data, {
      //     variant: 'primary'
      //   })
      // };

      this.dataChannel.onopen = () => {
      };

      this.dataChannel.onclose = () => {
      };

    },
    connectToDatachannel() {
      let incomingChannel;

      let timeOut = setTimeout(() => {
        this.connect_unavailable = true
        this.start_timer()
      }, 10000)

      this.pc.ondatachannel = (event) => {
        incomingChannel = event.channel;
        if (incomingChannel.label === "tigpok_chat") {
          incomingChannel.onopen = () => {
            this.call_success = true
            this.dataChannel.send('ping')
          };
          incomingChannel.onmessage = (event) =>{
            clearTimeout(timeOut)
            if(event.data === 'ping') {
              this.setStream()
              this.start_timer()
            } else {
              this.setStream()
              this.handleMessage({message: event.data})
            }
          };
          incomingChannel.onclose = () => {
          };
        }
      };

      if (!incomingChannel) {
        this.setDataChannel()
      }
    },
    sendWebRTCMessage() {
      if(this.message != '') {
        this.messages.push({
          value: this.message,
          my_mes: true,
        })

        this.dataChannel.send(this.message);

        this.message_box_jump()
      }

      this.message = ''
    },


    //GAME
    start_game() {
      if(this.choosed_bet != null && this.choosed_bet <= this.USER_ME.coins) {
        this.error_message = ""
        // this.start_stream()
        this.start_websocket()

        if(this.game_state === 'choose-bet') {
          this.game_state = 'loading'
          this.$store.dispatch('change_g_game_state', "loading")
        }
      } else if(this.choosed_bet > this.USER_ME.coins) {
        this.$bvToast.toast(`Not enough coins`, {
          title: 'Error',
          autoHideDelay: 2000,
          variant: 'danger'
        })

        this.bets.forEach(element => {
          element.active = false
        });

        this.choosed_bet = null
      } else {
        this.error_message = "Choose a bet!"
      }
    },
    end_game(){
      let seconds = this.time_for_exile

      setTimeout(() => {
        this.send_action('end_game')
        if(this.my_pool_number === 2) {
          this.game_state = 'waiting'
          this.$store.dispatch('change_g_game_state', "waiting")
          this.game_state_message = 'Waiting for game results'
        }
      }, this.time_for_exile * 1000)

      let timer = setInterval(() => {
        if(seconds >= 0) {
          // this.timer = this.convert_to_time(seconds)
          this.exile_timer = seconds
          seconds--
        } else {
          clearInterval(timer)
        }
      }, 1000)
    },
    close_game() {
      if(this.websocket instanceof WebSocket) {
        this.websocket.close()
      }
      this.stopVideo()
      clearTimeout(this.setTimer)
      this.timer = null
      Object.assign(this.$data, init_data());
      this.$store.dispatch('change_g_game_state', '')
      this.websocket = null
    },
    collect_pool(data) {
      this.pool_players = data.players
      this.players_in_pool = data.players.length
      this.pool_players.forEach((x, index) => {
        x.selected = false
        x.avatar_url = require(`@/assets/avatars/companion_${index+1}.svg`)
      })
      this.my_pool_number = this.pool_players.find(x => x.nick_name === this.USER_ME.nick_name).player

      this.exile_players = data.players.filter(x => x.player !== this.my_pool_number)
    },


    //STREAM
    async webcam_on (){
      await this.$store.dispatch('ME')
      try {
        this.localStream = await navigator.mediaDevices.getUserMedia({audio: true, video: true});
      } catch (error) {
        this.$bvToast.toast(`Unable to access camera`, {
          title: 'Error',
          autoHideDelay: 3000,
          variant: 'danger'
        })
      }
    },
    async start_stream() {
      this.$refs.start_video_btn.classList.add('load')
      this.webcam_on().then(response => {
        this.$refs.localVideo.classList.remove('d-none')
        this.$refs.localVideo.srcObject = this.localStream
        this.$refs.localVideo.muted = true
        this.localVideoSrcObject = this.$refs.localVideo.srcObject
        this.$refs.start_video_btn.classList.remove('load')
        this.game_state = "choose-bet"
      }).catch(error => {
        this.$refs.start_video_btn.classList.remove('load')
      })
    },

    stopVideo() {
      this.selectedBet = 0
      if(this.game_state === 'choose-bet') {
        this.game_state = ''
      }

      if (this.localVideoSrcObject !== null) {
        this.localStream.getTracks().forEach(track => track.stop());
      }
      this.localStream = null;
      if(this.$refs.localVideo) {
        this.$refs.localVideo.classList.add('d-none')
        this.$refs.localVideo.srcObject = null
      }
      this.localVideoSrcObject = null
    },
    async toggleVideo() {
      let message = ''
      if(this.accept_video) {
        this.localStream.getVideoTracks()[0].enabled = false;
        this.accept_video = false

        message = JSON.stringify({action: 'disable_cam', destination: this.companion.id})
        // this.websocket.send(message)
      } else {
        // this.localStream = await navigator.mediaDevices.getUserMedia({audio: true, video: true});
        this.localStream.getVideoTracks()[0].enabled = true;
        this.$refs.localVideo.srcObject = this.localStream
        this.accept_video = true

        message = JSON.stringify({action: 'enable_cam', destination: this.companion.id})
        // this.websocket.send(message)
      }
    },


    //MESSENGER
    handleMessage(data) {
      this.messages.push({
        value: data.message,
        my_mes: false,
      })

      this.message_box_jump()

      if(!this.chat_is_open) {
        this.new_messages_count++
      }
    },
    message_box_jump() {
      setTimeout(() => {
        this.$refs.message_box.scrollTop = this.$refs.message_box.scrollHeight + 1000
      }, 100)
    },
    sent_message() {
      if(this.message != '') {
        this.messages.push({
          value: this.message,
          my_mes: true,
        })
        var message = JSON.stringify({action: 'message', message: this.message, destination: this.companion.id})

        this.websocket.send(message)

        this.message_box_jump()
      }

      this.message = ''
    },
    open_chat() {
      // this.$refs.companion_cam.classList.add('faded')
      // this.$refs.localeVideo.classList.add('faded')
      this.$refs.slides.moveTo(0)
      this.chat_is_open = true
      this.new_messages_count = 0
    },
    close_chat() {
      this.$refs.companion_cam.classList.remove('faded')
      this.$refs.localeVideo.classList.remove('faded')
      this.chat_is_open = false
      this.$refs.slides.moveTo(1)
    },

    //HANDlERS
    async send_call(){
      this.create_peerConnection()
      this.companion = this.pool_players.find(x => x.player === 2)

      let message = JSON.stringify({action: 'call', message: '', destination: this.companion.id})
      this.websocket.send(message)
      this.messages = []
    },
    async handleCall(data) {
      this.create_peerConnection()

      let offer = await this.pc.createOffer()

      this.initiator_id = data.initiator_id
      let message = JSON.stringify({action:'offer', offer: offer, player_num: this.my_pool_number, destination: this.initiator_id})
      this.websocket.send(message)

      await this.pc.setLocalDescription(offer)
    },
    async handleOffer(data) {
      this.game_state = 'flow'
      await this.$store.dispatch('change_g_game_state', 'flow')

      this.initiator_id = data.initiator_id
      await this.pc.setRemoteDescription(data.offer)
      let answer = await this.pc.createAnswer()
      let message = JSON.stringify({action:'answer', answer: answer, player_num: this.my_pool_number, destination: this.initiator_id})
      this.websocket.send(message)
      await this.pc.setLocalDescription(answer)


      this.companion = this.pool_players.find(p => p.player === data.player_num)

      this.messages = []
    },
    async handleAnswer(data) {
      this.game_state = 'flow'
      await this.$store.dispatch('change_g_game_state', 'flow')

      this.initiator_id = data.initiator_id
      await this.pc.setRemoteDescription(data.answer)
      this.companion = this.pool_players.find(p => p.player === data.player_num)

      this.messages = []

    },
    async handleCandidate(candidate) {
      if (!this.pc) {
        return;
      }
      if (!candidate.candidate) {
        await this.pc.addIceCandidate(null);
      } else {
        await this.pc.addIceCandidate(candidate);
      }
    },

    handleDisconnect() {
      if(this.game_state !== 'result') {
        this.catch_error_dur_game()
      }
    },
    catch_error_dur_game() {
      this.close_game()
      this.close_rtc()
      this.$store.dispatch('set_home_show', false)
    },

    //WEBSOCKET
    start_websocket() {
      let token = localStorage.getItem('tigpok_token')
      this.websocket = new WebSocket(`wss://45-126-125-217.cloud-xip.com:8443/api/v1/ws/connect?token=${token}`)
      // this.websocket = new WebSocket(`ws://192.168.1.70:3000/api/v1/ws/connect?token=${token}`)



      let message =  JSON.stringify({'action': 'start_game', 'stake': this.choosed_bet, 'token': localStorage.getItem('tigpok_token')})

      this.websocket.onopen = e => {
        this.websocket.send(message)
      }

      this.websocket.onmessage = e => {
        console.log(e)
        let data = JSON.parse(e.data)
        switch (data.action) {
          case 'game_data':
            this.collect_pool(data)
            this.game_data = data

            if(data.status === 'done'){
              // this.start_stream()
              if(this.my_pool_number === 1) {
                if(!this.game_set) {
                  this.send_call()
                }
                this.game_state_message = 'Wait for response'
                this.game_state = "waiting"
                this.$store.dispatch('change_g_game_state', "waiting")
              } else if(this.my_pool_number === 2) {
                this.game_state_message = 'Wait for call'
                this.game_state = "waiting"
                this.$store.dispatch('change_g_game_state', "waiting")
              } else if(this.my_pool_number === 3) {
                this.game_state_message = `Waiting for ${this.exile_players[0]?.nick_name} and ${this.exile_players[1]?.nick_name} to finish their discussion.`
                this.game_state = "waiting"
                this.$store.dispatch('change_g_game_state', "waiting")
              }
            }
            break;

          case 'call':
            this.handleCall(data)
            break;

          case 'offer':
            this.handleOffer(data)
            break;

          case 'answer':
            this.handleAnswer(data)
            break;

          case 'message':
            this.handleMessage(data)
            break;

          case 'candidate':
            this.handleCandidate(data.candidate)
            break;

          case 'game_results':
            this.game_state = 'result'
            this.$store.dispatch('change_g_game_state', 'result')
            this.game_results = data

            this.game_results.decisions.forEach((x, index) => {
              let against_num = this.game_results.decisions[index].against
              if(against_num !== 'all') {
                this.game_results.decisions[index].against = this.pool_players.find(x => x.player === against_num).nick_name
              }
            })

            this.my_result = data.result.find(x => x.player === this.my_pool_number)
            this.$store.dispatch('ME')
            this.pool_players = []
            this.exile_players = []
            this.choosed_bet = null
            // this.websocket.close()
            this.close_rtc()
            break;

          case 'disconnect':
            this.handleDisconnect()
          break;

          case 'disable_cam':
            this.$refs.remoteVideo.srcObject = null
            this.remoteVideoSrcObject = null
            break

          case 'Error':
            this.catch_error_dur_game()
            break;
        }
      }

      this.websocket.onclose = e => {
        // if(e.code === 1000) {
        //   this.$bvToast.toast(e.reason, {
        //     title: 'Error',
        //     autoHideDelay: 10000,
        //     variant: 'danger'
        //   })
        // } el

        console.warn(e)

        this.$bvToast.toast(e.reason, {
          title: 'Error',
          autoHideDelay: 10000,
          variant: 'danger'
        })




        this.websocket = ''
        if(this.game_state === 'loading') {
          this.game_state = ''
          this.$store.dispatch('change_g_game_state', '')
        }

        this.close_game()
      }

      this.websocket.onerror = (error) => {
        console.log(error)
        this.close_game()
      }

      this.websocket.addEventListener('error', e => {})
    },

    send_action(action_type) {
      let token = localStorage.getItem('tigpok_token')

      if(action_type === 'start_game') {
        var message =  JSON.stringify({'action': 'start_game', 'stake': this.choosed_bet, 'token': token})
        this.websocket.send(message)
      }

      if(action_type === 'end_game') {
        var message = JSON.stringify({
          action: 'end_game',
          player: this.my_pool_number,
          nick_name: this.USER_ME.nick_name,
          id: this.pool_players.find(x => x.player == this.my_pool_number).id,
          game_id: this.game_data.game_id,
          token: token,
          against: this.selected_player,
          share: this.choosed_split.split('/')
        })

        this.websocket.send(message)
      }
    },
  },

  mounted() {
    // this.getBestStunServers()

    // this.axios('twilio/get_twilio_ice_servers', {
    //   headers: {'Authorization': 'Bearer ' + localStorage.getItem('tigpok_token')}
    // })
    //     .then(response => {
    //       this.turnServers = this.turnServers.concat(response.data.slice(1))
    //
    //     })

    // this.getSTUNservers()

    // setTimeout(() => {
    //   this.game_state = 'waiting'
    // }, 3000)

    let cW = document.documentElement.clientWidth

    if(cW < 769) {
      this.mobile = true
    }
  },

  destroyed() {
    this.close_game()
  }
}

</script>

<style lang="scss" scoped>
.choose-divide {
  transition: .3s;
  overflow: hidden;
  height: 140px;
}

.choose-divide.closed {
  opacity: 0;
  transform: scale(.8);
  height: 0;
}

.shake-enter-active, .shake-leave-active {
  transform: rotate(10deg) scale(1.4);
}

.shake-enter, .shake-leave-to {
  transform: rotate(-5deg) scale(0.8);
}
</style>